// Import jQuery from library
import jQuery from "jquery";
import axios from 'axios';
import * as bootstrap from 'bootstrap';
// import * as $ from "jquery";

// Import Datatables from library
import dt from 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.css';

import("datatables.net-bs5")
// import('datatables.net-buttons-bs5')
import('datatables.net-fixedheader-bs5')

// import "datatables.net-bs5/css/dataTables.bootstrap5.css"
// import "datatables.net-buttons-bs5/css/buttons.bootstrap5.css"

import colvis from 'datatables.net-buttons/js/buttons.colVis'
jQuery.fn.DataTable = dt;
jQuery.fn.dataTable = dt;

/*new dt()
dtbs5()
dtfhbs5()
colvis()
dtbbs5()*/

//dt();

jQuery(() => {
    jQuery('.datatable').DataTable({
        buttons: [
           'colvis'
        ],

        order: [[1, 'asc']],

        ordering: true,
        lengthChange: true,
        searching: true,
        stateSave: true,            // Keep the current state on reload
        stateSaveCallback: function(settings, data) {
            let instanceKey = window.location.pathname.replace(/\/([0-9]+)/, '')

            localStorage.setItem( settings.sTableId + instanceKey, JSON.stringify(data) )
        },
        stateLoadCallback: function(settings) {
            let instanceKey = window.location.pathname.replace(/\/([0-9]+)/, '')

            return JSON.parse( localStorage.getItem( settings.sTableId + instanceKey ) )
        },
        fixedHeader: true,
        initComplete: dataTableFilters,

        displayStart: 0,
        lengthMenu: [[100, 250, 500, 1000, -1], [100, 250, 500, 1000, "Alles"]],
        orderCellsTop: true,
        pageLength: 100,
        pagingType: "full_numbers",

        search: {
            caseInsensitive: true,
            regex: true,
            smart: true
        },
        searchDelay: 300,
        stateDuration: (60 * 60),       // 60 seconds * 60 minutes = 1 hour = 3600s

        language: {
            decimal: ",",
            emptyTable: "Geen data beschikbaar",
            info: "Toon _START_ tot _END_ van _TOTAL_ regels",
            infoEmpty: "Geen regels om te tonen",
            infoFiltered: "(gefilterd van _MAX_ regels)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Toon _MENU_ regels",
            loadingRecords: "Laden...",
            processing: "",
            search: "Zoeken: ",
            zeroRecords: "Geen resultaten gevonden",
            paginate: {
                first: "Eerste",
                last: "Laatste",
                next: "Volgende",
                previous: "Vorige"
            }
        },
    });

    let t = jQuery('.datatable-with-counter').DataTable({
        buttons: [
            {
                extend: 'colvis',
                // columns: 'th:nth-child(n+2)',
                text: 'Kolommen'
            }
        ],

        ordering: true,
        lengthChange: true,
        searching: true,
        stateSave: true,            // Keep the current state on reload
        stateSaveCallback: function(settings, data) {
            let instanceKey = window.location.pathname.replace(/\/([0-9]+)/, '')

            localStorage.setItem( settings.sTableId + instanceKey, JSON.stringify(data) )
        },
        stateLoadCallback: function(settings) {
            let instanceKey = window.location.pathname.replace(/\/([0-9]+)/, '')

            return JSON.parse( localStorage.getItem( settings.sTableId + instanceKey ) )
        },
        fixedHeader: true,
        initComplete: dataTableFilters,

        displayStart: 0,
        lengthMenu: [[100, 250, 500, 1000, -1], [100, 250, 500, 1000, "Alles"]],
        orderCellsTop: true,
        pageLength: 100,
        pagingType: "full_numbers",

        search: {
            caseInsensitive: true,
            regex: true,
            smart: true
        },
        searchDelay: 300,
        stateDuration: (60 * 60),       // 60 seconds * 60 minutes = 1 hour = 3600s

        language: {
            decimal: ",",
            emptyTable: "Geen data beschikbaar",
            info: "Toon _START_ tot _END_ van _TOTAL_ regels",
            infoEmpty: "Geen regels om te tonen",
            infoFiltered: "(gefilterd van _MAX_ regels)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Toon _MENU_ regels",
            loadingRecords: "Laden...",
            processing: "",
            search: "Zoeken: ",
            zeroRecords: "Geen resultaten gevonden",
            paginate: {
                first: "Eerste",
                last: "Laatste",
                next: "Volgende",
                previous: "Vorige"
            }
        },
    });

    t.on('order.dt search.dt', function () {
        let i = 1;

        console.log(t.api());
        t.api().cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
            this.data(i++);
        });
    });
    // t.draw();

    jQuery('.datatable-linkoffers-paid').DataTable({
        ajax: jQuery('.datatable-linkoffers-paid').attr('data-url'),
        processing: true,
        serverSide: true,
        // initComplete: dataTableFilters,

        fixedHeader: {
            header: true,
            footer: true,
            footerOffset: jQuery('.phpdebugbar').outerHeight(),

        },

        columns: [
            {data: 'website', orderable: true},
            {data: 'branche', orderable: true},
            {data: 'da', orderable: true},
            {data: 'tf', orderable: true},
            // {data: 'language', orderable: true},
            {data: 'visitors', orderable: false},
            {data: 'linkpartner', orderable: false},
            {data: 'exchange_possible', orderable: false},
            {data: 'blog_possible', orderable: false},
            {data: 'textlink', orderable: false},
            {data: 'price_excl_writing', orderable: false},
            {data: 'price_incl_writing', orderable: false},
            {data: 'price_niche_edit', orderable: false},
            {data: 'advertorial_notice', orderable: false},
            {data: 'actions', orderable: false}
        ],

        ordering: true,
        lengthChange: true,
        searching: true,
        stateSave: true,            // Keep the current state on reload

        displayStart: 0,
        lengthMenu: [[100, 250, 500, 1000, -1], [100, 250, 500, 1000, "Alles"]],
        orderCellsTop: true,
        pageLength: 100,
        pagingType: "full_numbers",

        search: {
            caseInsensitive: true,
            regex: true,
            smart: true
        },
        searchDelay: 300,
        stateDuration: (60 * 60),       // 60 seconds * 60 minutes = 1 hour = 3600s

        language: {
            decimal: ",",
            emptyTable: "Geen data beschikbaar",
            info: "Toon _START_ tot _END_ van _TOTAL_ regels",
            infoEmpty: "Geen regels om te tonen",
            infoFiltered: "(gefilterd van _MAX_ regels)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Toon _MENU_ regels",
            loadingRecords: "Laden...",
            processing: "",
            search: "Zoeken: ",
            zeroRecords: "Geen resultaten gevonden",
            paginate: {
                first: "Eerste",
                last: "Laatste",
                next: "Volgende",
                previous: "Vorige"
            }
        },
    });

    jQuery('.datatable-linkoffers-free').DataTable({
        ajax: jQuery('.datatable-linkoffers-free').attr('data-url'),
        processing: true,
        serverSide: true,
        fixedHeader: true,
        // initComplete: dataTableFilters,

        columns: [
            {data: 'website', orderable: true},
            {data: 'branche', orderable: true},
            {data: 'da', orderable: true},
            {data: 'tf', orderable: true},
            // {data: 'language', orderable: true},
            {data: 'linkpartner', orderable: false},
            {data: 'paid_possible', orderable: false},
            {data: 'exchange_possible', orderable: false},
            {data: 'blog_possible', orderable: false},
            {data: 'actions', orderable: false}
        ],

        ordering: true,
        lengthChange: true,
        searching: true,
        stateSave: true,            // Keep the current state on reload

        displayStart: 0,
        lengthMenu: [[100, 250, 500, 1000, -1], [100, 250, 500, 1000, "Alles"]],
        orderCellsTop: true,
        pageLength: 100,
        pagingType: "full_numbers",

        search: {
            caseInsensitive: true,
            regex: true,
            smart: true
        },
        searchDelay: 300,
        stateDuration: (60 * 60),       // 60 seconds * 60 minutes = 1 hour = 3600s

        language: {
            decimal: ",",
            emptyTable: "Geen data beschikbaar",
            info: "Toon _START_ tot _END_ van _TOTAL_ regels",
            infoEmpty: "Geen regels om te tonen",
            infoFiltered: "(gefilterd van _MAX_ regels)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Toon _MENU_ regels",
            loadingRecords: "Laden...",
            processing: "",
            search: "Zoeken: ",
            zeroRecords: "Geen resultaten gevonden",
            paginate: {
                first: "Eerste",
                last: "Laatste",
                next: "Volgende",
                previous: "Vorige"
            }
        },
    });

    jQuery('body').delegate('.linkitem-collapse-toggle', 'click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let row = jQuery(this).parents('tr', 0);
        jQuery('.linkitem-expandable', row).removeClass('collapsed');
        jQuery('.linkitem-collapse-toggle', row).remove();
    });

    let tableDataTable;
    /*for(let table of jQuery.fn.dataTable.tables()) {
        console.log(jQuery(table).DataTable())
        tableDataTable = jQuery(table).DataTable().Buttons().container().prependTo(
            jQuery('.dataTables_wrapper .row:nth-child(1) .col-md-6:nth-child(1)', jQuery(table).parents('.card-body', 0))
        );

        jQuery('.dt-down-arrow', jQuery(table).DataTable().Buttons().container())
            .remove();
        jQuery('.buttons-collection', jQuery(table).DataTable().Buttons().container())
            .addClass('dropdown-toggle')
            .addClass('btn')
            .addClass('btn-secondary');
        jQuery('.dt-button-collection button', jQuery(table).DataTable().Buttons().container())
            .addClass('btn')
            .addClass('btn-link');
        jQuery('.dt-buttons')
            .addClass('float-start')
            .addClass('me-4');
    }*/

    // jQuery('.dt-button').on('click', function() {
    //     jQuery('.dt-button-collection button').addClass('btn')
    //         .addClass('btn-link');
    //});
});

function dataTableFilters() {
    var table = this.api();
    var _element = this;

    let container = document.createElement('div')
    container.classList = 'actions mb-3'
    container.style.width = 'max-content'
    let tableParent = table.table().node().parentNode
    console.log(tableParent)
    tableParent.insertBefore(container, table.table().node())

    let tableId = _element.attr('id') + window.location.pathname
    let visibility = JSON.parse(localStorage.getItem(tableId + '/columnVisibility')) || {};

    let buttonGroup = document.createElement('div')
    buttonGroup.classList.add('btn', 'btn-group')
    container.appendChild(buttonGroup)

    let dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');
    buttonGroup.appendChild(dropdown);

    let dropdownButton = document.createElement('button');
    dropdownButton.classList.add('btn', 'btn-primary', 'dropdown-toggle', 'mb-3');
    dropdownButton.setAttribute('type', 'button');
    dropdownButton.setAttribute('id', 'dropdownColumnButton' + table.table().node().id);
    dropdownButton.setAttribute('data-bs-toggle', 'dropdown');
    dropdownButton.setAttribute('aria-expanded', 'false');
    dropdownButton.textContent = 'Toggle Kolommen';
    dropdown.appendChild(dropdownButton);

    let dropdownMenu = document.createElement('ul');
    dropdownMenu.classList.add('dropdown-menu');
    dropdownMenu.setAttribute('aria-labelledby', 'dropdownColumnButton' + table.table().node().id);
    dropdown.appendChild(dropdownMenu);

    let clearFiltersButton = document.createElement('button');
    clearFiltersButton.classList.add('btn', 'btn-outline-secondary', 'mb-3');
    clearFiltersButton.textContent = 'Zoekfilters verwijderen';
    buttonGroup.appendChild(clearFiltersButton);

    clearFiltersButton.addEventListener('click', function() {
        clearAllFilters();
    });

    function addFiltersToColumn(column, _element, originalIndex, header) {
        let columnIndex = table.column(originalIndex).index('visible');

        // Remove existing filters to avoid duplication
        jQuery("thead tr td", _element).eq(columnIndex).empty();

        if (jQuery("thead tr td", _element).eq(columnIndex).hasClass('filter-input')) {
            var input = jQuery('<input type="text" class="form-control form-control-sm" />')
                .appendTo(jQuery("thead tr td", _element).eq(columnIndex))
                .on("keyup", function () {
                    var value = jQuery(this).val();
                   localStorage.setItem(tableId + '/filterInput/' + header.innerText, value);
                    column.search(value).draw();
                }).val(column.search());
    
            var savedValue = localStorage.getItem(tableId + '/filterInput/' + header.innerText);
            if (savedValue) {
                input.val(savedValue);
                column.search(savedValue).draw();
            }
        }


        if (jQuery("thead tr td", _element).eq(columnIndex).hasClass('filter-select')) {
            var select = jQuery('<select class="form-select form-select-sm"><option value=""></option></select>')
                .appendTo(jQuery("thead tr td", _element).eq(columnIndex))
                .on('change', function () {
                    let val = jQuery.fn.dataTable.util.escapeRegex(
                        jQuery(this).val()
                    );

                    column.search(val).draw();
                })

            column.data().unique().sort().each(function(d, j) {
                let colID = makeid(12);
                let selected = column.search() == stripHtml(d) ? ' selected="selected"' : ''
                select.append('<option id="' + colID + '" value="' + stripHtml(d) + '"' + selected + '>' + stripHtml(d) + '</option>')
            })

        }
    }

    table.columns().every(function(index) {
        let column = this;
        let header = column.header(); 
        let originalIndex = index;

        let menuItem = document.createElement('li');
        let link = document.createElement('a');
        link.classList.add('dropdown-item');
        link.dataset.column = originalIndex;

        let checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.style.marginRight = '5px';
        checkbox.dataset.column = originalIndex;

        link.appendChild(checkbox);
        link.appendChild(document.createTextNode(header.textContent));
        menuItem.appendChild(link);
        dropdownMenu.appendChild(menuItem);

        link.addEventListener('click', function(e) {
            e.preventDefault();
            if (e.target.tagName !== 'INPUT') {
                let checkbox = link.querySelector('input[type=checkbox]');
                toggleColumnVisibility(checkbox, column, originalIndex);
                
            }
        });

        checkbox.addEventListener('click', function(e) {
            e.stopPropagation();
            toggleColumnVisibility(this, column, originalIndex);
            
        })

        let isVisible = visibility[originalIndex];
        if (isVisible !== undefined) {
             column.visible(isVisible);
            checkbox.checked = isVisible;
        } else {
            checkbox.checked = column.visible();
        }

        if (column.visible()) {
            let columnIndex = table.column(originalIndex).index();
            addFiltersToColumn(column, _element, columnIndex, header);

        }
    })

    const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
    dropdownElementList.forEach(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl));

    function toggleColumnVisibility(checkbox, column, originalIndex) {
        let isVisible = !column.visible();
        column.visible(isVisible);

        visibility[originalIndex] = isVisible;
        localStorage.setItem(tableId + '/columnVisibility', JSON.stringify(visibility));

        checkbox.checked = isVisible;

        table.columns().every(function(index) {
            let column = this;
            let header = column.header();
            let originalIndex = index;
            if (column.visible()) {
                addFiltersToColumn(column, _element, originalIndex, header);
            }
        });
    }

    function clearAllFilters() {
        table.columns().every(function() {
            let column = this;
            column.search('').draw();
            localStorage.removeItem(tableId + '/filterInput/' + column.header().innerText);
        });

        jQuery("thead tr td", _element).each(function() {
            jQuery(this).empty();
        });

        table.columns().every(function(index) {
            let column = this;
            let header = column.header();
            let originalIndex = index;
            if (column.visible()) {
                addFiltersToColumn(column, _element, originalIndex, header);
            }
        });
    }
}


function stripHtml(html)
{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent.trim() || tmp.innerText.trim() || "";
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

