<template>
    <div class="card">
        <div class="card-body">
            <div class="alert alert-danger" v-if="validation.errors.length > 0">
                <ul>
                    <li v-for="msg in validation.errors" :key="msg">{{ msg }}</li>
                </ul>
            </div>

            <div class="row">
                <div class="mb-3 col-4">
                    <label for="priority" id="priorityLabel" class="form-label">Prioriteit*</label>
                        <multiselect
                        v-model="priority"
                        :options="['Hoog', 'Normaal']"
                        :searchable="true"
                        id="priority"
                        :class="{'validation-error': this.validation.errorFields.includes('priority')}"
                        @change="validateForm()"
                        ></multiselect>

                    <div class="alert alert-danger" v-if="errors.priority">
                        <ul class="mb-0">
                            <li v-for="error in errors.priority" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-3 col-4">
                    <label for="project_id" id="project_idLabel" class="form-label">Project*</label>
                    <multiselect
                        v-model="projectId"
                        :options="projects"
                        :searchable="true"
                        id="project_id"
                        :class="{'validation-error': this.validation.errorFields.includes('projectId')}"
                        @change="validateForm()"
                    ></multiselect>

                    <div class="alert alert-danger" v-if="errors.project_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.project_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>


            <div class="mb-3 col-4">
                <label for="status_id" id="status_idLabel" class="form-label">Status*</label>
                <multiselect
                    v-model="statusId"
                    :options="status"
                    :searchable="true"
                    id="status_id"
                    :class="{'validation-error': this.validation.errorFields.includes('statusId')}"
                    @change="validateForm()"
                ></multiselect>

                <div class="alert alert-danger" v-if="errors.status_id">
                    <ul class="mb-0">
                        <li v-for="error in errors.status_id" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="mb-3 col-4 d-flex flex-column align-items-center">
                <label class="form-label">Betaald</label>
                <div class="btn-group d-block" role="group" aria-label="Betaald">
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': payed == 'is'}"
                            @click="this.payed = 'Ja'">Ja
                    </button>
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': payed == 'is not'}"
                            @click="this.payed = 'Nee'">Nee
                    </button>
                </div>

                <div class="alert alert-danger" v-if="errors.payed">
                    <ul class="mb-0">
                        <li v-for="error in errors.payed" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3 col-4">
                <label for="writer_id" id="writer_idLabel" class="form-label">Schrijver</label>
                <multiselect
                    v-model="writerId"
                    :options="users"
                    :searchable="true"
                    id="writer_id"
                    :class="{'validation-error': this.validation.errorFields.includes('writerId')}"
                    @change="validateForm()"
                ></multiselect>

                <div class="alert alert-danger" v-if="errors.writer_id">
                    <ul class="mb-0">
                        <li v-for="error in errors.writer_id" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3 col-4">
                <label for="active_date" class="form-label" id="active_dateLabel">Startmaand*</label>
                    <input type="month" name="active_date" id="active_date" class="form-control"
                    aria-labelledby="active_dateLabel" v-model="activeDate" :min="minDate"
                    :class="{'validation-error': this.validation.errorFields.includes('activeDate')}"
                    @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.active_date">
                        <ul class="mb-0">
                            <li v-for="error in errors.active_date" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label for="anchor_text" class="form-label" id="anchor_textLabel">Ankerteksten*</label>
            </div>
            <div class="col-5">
                <label for="anchor_url" class="form-label" id="anchor_urlLabel">Anker URLs*</label>
            </div>
        </div>

        <div class="row mb-2" v-for="(text, index) in anchorTexts" :key="index">
            <div class="col-6">
                <input type="text"
                    :name="'anchor_texts[+ (' + index + ')]'"
                    :id="'anchor_text_' + (index + 1)"
                    class="form-control"
                    v-model="anchorTexts[index]"
                    aria-labelledby="anchor_textLabel">

                    <div class="alert alert-danger" v-if="errors.anchor_texts && errors.anchor_texts[index].length">
                        <ul class="mb-0">
                            <li v-for="(error, i) in errors.anchor_texts[index]" :key="i" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>

            <div class="col-5">
                <input type="text"
                    :name="'anchor_urls[+ (' + index + ')]'"
                    :id="'anchor_url_' + (index + 1)"
                    class="form-control"
                    v-model="anchorUrls[index]"
                    aria-labelledby="anchor_urlLabel">

                    <div class="alert alert-danger" v-if="errors.anchor_urls && errors.anchor_urls[index].length">
                        <ul class="mb-0">
                            <li v-for="(error, i) in errors.anchor_urls[index]" :key="i" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>

            <div class="col-1">
                <button type="button" class="btn btn-outline-success" v-if="anchorUrls.length < 2 && anchorTexts.length < 2" @click="addAnchor">
                    <i class="las la-plus"></i>
                </button>

                <button type="button" class="btn btn-outline-danger" v-if="anchorUrls.length == 2 && anchorTexts.length == 2 && index == 1" @click="removeAnchor">
                    <i class="las la-minus"></i>
                </button>
            </div>
        </div>

        <div class="mb-3">
            <label for="linkbuilding_websites" class="form-label" id="linkbuilding_websitesLabel">Linkbuilding Website</label>
            <input type="text" name="linkbuilding_website" id="linkbuilding_website" class="form-control"
            aria-labelledby="linkbuilding_websiteLabel" v-model="linkbuildingWebsites"
            :class="{'validation-error': this.validation.errorFields.includes('linkbuildingWebsites')}"
            @change="validateForm()">

            <div class="alert alert-danger" v-if="errors.linkbuilding_websites">
                <ul class="mb-0">
                    <li v-for="error in errors.linkbuilding_websites" :key="error" class="mb-0">{{ error }}</li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="mb-3 col-6">
                <label for="article_url" class="form-label" id="article_urlLabel">Artikel URL</label>
                <input type="text" name="article_url"
                id="article_url"
                class="form-control"
                aria-labelledby="article_urlLabel"
                v-model="articleUrl"
                :class="{'validation-error': this.validation.errorFields.includes('articleUrl')}"
                @change="validateForm()">

                <div class="alert alert-danger" v-if="errors.article_url">
                    <ul class="mb-0">
                        <li v-for="error in errors.article_url" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3 col-6">
                <label for="comments" class="form-label" id="commentsLabel">Opmerkingen</label>
                <input type="text" name="comments" id="comments" class="form-control"
                aria-labelledby="commentsLabel" v-model="comments"
                :class="{'validation-error': this.validation.errorFields.includes('comments')}"
                @change="validateForm()">

                <div class="alert alert-danger" v-if="errors.comments">
                    <ul class="mb-0">
                        <li v-for="error in errors.comments" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label for="drive_url" class="form-label" id="drive_urlLabel">Drive URL</label>
            <input type="text" name="drive_url" id="drive_url" class="form-control"
            aria-labelledby="drive_urlLabel" v-model="driveUrl"
            :class="{'validation-error': this.validation.errorFields.includes('driveUrl')}"
            @change="validateForm()">

            <div class="alert alert-danger" v-if="errors.drive_url">
                <ul class="mb-0">
                    <li v-for="error in errors.drive_url" :key="error" class="mb-0">{{ error }}</li>
                </ul>
            </div>
        </div>


        </div>
        <div class="card-footer">
            <button type="submit" class="btn btn-primary" @click="submitForm()">
                <i class="las la-save"></i> Opslaan
            </button>

            <div class="alert alert-danger mt-3 mb-0" v-if="hasErrors">
                Er zijn fouten opgetreden bij het opslaan van het formulier.
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import axios from 'axios'
    import Tooltip from 'bootstrap/js/src/tooltip'

    export default {
        components: {
            Tooltip,
            Multiselect
        },
        data() {
            return {
                'textsId': null,
                'priority':'Normaal',
                'projectId': null,
                'projects': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'statusId': 1,
                'status': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'payed': 'is not',
                'writerId': null,
                'users': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'activeDate': null,
                'anchorText': null,
                'anchorTexts': [''],
                'anchorUrl': null,
                'anchorUrls': [''],
                'linkbuildingWebsites': null,
                'articleUrl': null,
                'comments': null,
                'driveUrl': null,
                'creator_id': null,
                'validation': {
                    'errors': [],
                    'errorFields': []
                },
                'errors': {},
                'hasErrors': false
            }
        },
        computed: {
            minDate() {
                const today = new Date();
                const year = today.getFullYear();
                let month = today.getMonth() + 1;

                if (month > 12) {
                    month = 1;
                    year += 1;
                }

                const formattedMonth = month.toString().padStart(2, '0');

                return `${year}-${formattedMonth}`;
            }
        },
        methods: {
        submitForm() {
            this.validateForm()

            if (this.validation.errors.length == 0) {
                let data = {
                    'priority': this.priority,
                    'project_id': this.projectId,
                    'status_id': this.statusId,
                    'payed': this.payed,
                    'active_date': this.activeDate + '-01',
                    'writer_id': this.writerId,
                    'anchor_texts': this.anchorTexts,
                    'anchor_urls': this.anchorUrls,
                    'linkbuilding_websites': this.linkbuildingWebsites,
                    'article_url': this.articleUrl,
                    'comments': this.comments,
                    'drive_url': this.driveUrl,
                    'creator_id': this.creatorId
                }

                this.errors = {}
                this.hasErrors = false
                if (this.textsId != null) {
                    axios.patch('/api/linkbuildingtexts/' + this.textsId, data).then(() => {
                        window.location = '/texts/overview/';
                    }).catch(error => {
                        this.errors = error.response.data.errors
                        this.hasErrors = true
                    })
                } else {
                    axios.post('/api/linkbuildingtexts', data).then(() => {
                        // window.location = '/texts/overview/';

                        this.$notify({
                            id: 'text-form-sent',
                            title: 'Tekst opgeslagen',
                            text: 'De tekst is succesvol opgeslagen',
                            type: 'success',
                            duration: 3000
                        })

                        this.linkbuildingWebsites = ''


                    }).catch(error => {
                        this.errors = error.response.data.errors

                        if (
                            error.response.data.errors["anchor_texts.0"] ||
                            error.response.data.errors["anchor_texts.1"]
                        ) {
                            this.errors.anchor_texts = [[],[]];
                            this.errors.anchor_urls = [[],[]];
                        }

                        if (error.response.data.errors["anchor_texts.0"]) {
                            this.errors.anchor_texts[0] = error.response.data.errors["anchor_texts.0"]
                        }

                        if (error.response.data.errors["anchor_urls.0"]) {
                            this.errors.anchor_urls[0] = error.response.data.errors["anchor_urls.0"]
                        }

                        if (error.response.data.errors["anchor_texts.1"]) {
                            this.errors.anchor_texts[1] = error.response.data.errors["anchor_texts.1"]
                        }

                        if (error.response.data.errors["anchor_urls.1"]) {
                            this.errors.anchor_urls[1] = error.response.data.errors["anchor_urls.1"]
                        }

                        this.hasErrors = true
                    })
                }
            }
        },

        validateForm() {
            this.validation.errors = [];
            this.validation.errorFields = []
            if (this.projectId != null && this.projectId == '') {
                this.validation.errors.push('Geef een klant aan om toe te voegen')
                this.validation.errorFields.push('projectId')
            }


        },

        addAnchor() {
            if (this.anchorTexts.length < 2 && this.anchorUrls.length < 2) {
                this.anchorTexts.push('');
                this.anchorUrls.push('');
            }
        },

        removeAnchor(index) {
            if (this.anchorTexts.length > 1 && this.anchorUrls.length > 1) {
                this.anchorTexts.splice(index, 1);
                this.anchorUrls.splice(index, 1);
            }
        }
    },

    mounted() {
        if (this.$route.params.id) {

            this.emitter.emit('set-title', 'Linkbuilding tekst bewerken')

        this.textsId = this.$route.params.id
        fetch('/api/linkbuildingtexts/' + this.textsId)
        .then(resp => resp.json())
            .then((json) => {
                this.priority = json.priority
                this.projectId = json.project_id
                this.statusId = json.status_id
                this.payed = json.payed
                const activeDateParts = json.active_date.split('-')
            	this.activeDate = `${activeDateParts[0]}-${activeDateParts[1]}`
                this.writerId = json.writer
                this.anchorTexts = json.anchor_text.split('¤ ')
                this.anchorUrls = json.anchor_url.split('¤ ')
                this.linkbuildingWebsites = json.linkbuilding_websites
                this.articleUrl = json.article_url
                this.comments = json.comments
                this.driveUrl = json.drive_url
            })

            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Overzicht',
                    url: '/texts/overview'
                }
            ])

            this.editMode = true
        } else {
            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Overzicht',
                    url: '/texts/overview'
                }
            ])
            this.emitter.emit('set-title', 'Linkbuilding tekst aanmaken')
        }

        fetch('/api/linkbuildingtextsstatus')
            .then(resp => resp.json())
            .then((json) => {
                this.status = [];
                for (let s in json) {
                    let status = json[s];
                    this.status.push({
                        'label': status.status_name,
                        'value': status.id
                    })
                }
            })

        fetch('/api/togglprojects')
            .then(resp => resp.json())
            .then(json => {
                this.projects = []
                for (let p in json) {
                    let project = json[p]
                    this.projects.push({
                        'label': project.project_name,
                        'value': project.toggl_project_id
                    })

                }
            })

        fetch('/api/users')
            .then(resp => resp.json())
            .then((json) => {
                this.users = [];
                for (let u in json) {
                    let user = json[u];
                    this.users.push({
                        'label': user.name,
                        'value': user.id,
                        'role': user.role
                    });
                }

                this.users.sort((a, b) => {
                    if (a.role === 'Tekstschrijver' && b.role !== 'Tekstschrijver') {
                        return -1;
                    } else if (a.role !== 'Tekstschrijver' && b.role === 'Tekstschrijver') {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            });

    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7) url(../../../img/loading.gif) no-repeat center;
        background-size: 200px;
        z-index: 9999;
    }

    .tags {
        display: flex;
        gap: 5px;
        margin-top: 5px;
    }

    .tag {
        background-color: #007bff;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }

    .remove-tag {
        margin-left: 5px;
        cursor: pointer;
        color: red;
    }
</style>
