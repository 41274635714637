<template>
    <div class="card">
        <div class="card-body">
            <div class="alert alert-danger" v-if="validation.errors.length > 0">
                <ul>
                    <li v-for="msg in validation.errors" :key="msg">{{ msg }}</li>
                </ul>
            </div>

            <div class="mb-3">
                <p>Weet je niet wat je moet invullen? hang met je muis over het tekstveld heen voor extra info!</p>
            </div>

            <div class="row">
                <div class="mb-3 col-6">
                    <label for="project_id" id="project_idLabel" class="form-label">Project*</label>
                    <multiselect
                        v-model="projectId"
                        :options="projects"
                        :searchable="true"
                        id="project_id"
                        :class="{'validation-error': this.validation.errorFields.includes('projectId')}"
                        @change="validateForm()"
                        >
                    </multiselect>


                    <div class="alert alert-danger" v-if="errors.project_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.project_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-3 col-6">
                    <label for="title" class="form-label" id="titleLabel">Titel*</label>
                    <input type="text" name="title" id="title" class="form-control"
                            aria-labelledby="titleLabel" v-model="title"
                            :class="{'validation-error': this.validation.errorFields.includes('title')}"
                            @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.title">
                        <ul class="mb-0">
                            <li v-for="error in errors.title" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label for="description" class="form-label" id="descriptionLabel">Omschrijving*</label>
                <textarea id="description" class="form-control"
                        aria-labelledby="descriptionLabel" v-model="description"
                        :class="{'validation-error': this.validation.errorFields.includes('description')}"
                        @change="validateForm()"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="'Vertel hier kort wat over de te schrijven tekst: Wat voor soort tekst er geschreven moet worden en waarom, eventueel achtergrondinfo'"
                        ></textarea>

                <div class="alert alert-danger" v-if="errors.description">
                    <ul class="mb-0">
                        <li v-for="error in errors.description" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="mb-3 col-6">
                    <label for="target_group" class="form-label" id="target_groupLabel">Doelgroep*</label>
                    <input type="text" name="target_group" id="target_group" class="form-control"
                    aria-labelledby="target_groupLabel" v-model="targetGroup"
                    :class="{'validation-error': this.validation.errorFields.includes('targetGroup')}"
                    @change="validateForm()"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="'Korte beschrijving van de doelgroep (B2C of B2B, zoekintentie: kopen, informatie etc)'"
                    >

                    <div class="alert alert-danger" v-if="errors.target_group">
                        <ul class="mb-0">
                            <li v-for="error in errors.target_group" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-4 col-6">
                    <label for="tone_of_voice" class="form-label" id="tone_of_voiceLabel">Tone of Voice*</label>
                    <input type="text" name="tone_of_voice" id="tone_of_voice" class="form-control"
                    aria-labelledby="tone_of_voiceLabel" v-model="toneOfVoice"
                    :class="{'validation-error': this.validation.errorFields.includes('toneOfVoice')}"
                    @change="validateForm()"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="'Jij/U; formeel/informeel'"
                    >

                    <div class="alert alert-danger" v-if="errors.tone_of_voice">
                        <ul class="mb-0">
                            <li v-for="error in errors.tone_of_voice" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="mb-4 col-6">
                    <label for="primary_search_word" class="form-label" id="primary_search_wordLabel">Primair zoekwoord*</label>
                    <input type="text" name="primary_search_word" id="primary-search-word" class="form-control"
                    aria-labelledby="primary_search_wordLabel" v-model="primarySearchWord"
                    :class="{'validation-error': this.validation.errorFields.includes('primarySearchWord')}"
                    @change="validateForm()"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="'Hier zet je het belangrijkste zoekwoord (meeste zoekvolume, best passend bij onderwerp)'"
                    >

                    <div class="alert alert-danger" v-if="errors.primary_search_word">
                        <ul class="mb-0">
                            <li v-for="error in errors.primary_search_word" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-4 col-6">
                    <label for="secondary_search_word" class="form-label" id="secondary_search_wordLabel">Secundair(e) zoekwoord(en)*</label>
                    <input type="text" name="secondary_search_word" id="secondary-search-word" class="form-control"
                    aria-labelledby="secondary_search_wordLabel" v-model="secondarySearchWord"
                    :class="{'validation-error': this.validation.errorFields.includes('secondarySearchWord')}"
                    @change="validateForm()"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="'Met welke zoekwoorden kan de tekstschrijver nog meer rekening houden in deze tekst?'"
                    >

                    <div class="alert alert-danger" v-if="errors.secondary_search_word">
                        <ul class="mb-0">
                            <li v-for="error in errors.secondary_search_word" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label for="internal_links" class="form-label" id="internal_linksLabel">Interne links*</label>
                <textarea id="internal_links" class="form-control"
                        aria-labelledby="internal_linksLabel" v-model="internalLinks"
                        :class="{'validation-error': this.validation.errorFields.includes('internalLinks')}"
                        @change="validateForm()"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="'ankertekst - url. Zet hier alle interne links conform jouw zoekwoord cluster neer.'"
                        ></textarea>

                <div class="alert alert-danger" v-if="errors.internal_links">
                    <ul class="mb-0">
                        <li v-for="error in errors.internal_links" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3">
                <label for="example_pages" class="form-label" id="example_pagesLabel">Voorbeeld pagina's*</label>
                <textarea id="example_pages" class="form-control"
                        aria-labelledby="example_pagesLabel" v-model="examplePages"
                        :class="{'validation-error': this.validation.errorFields.includes('examplePages')}"
                        @change="validateForm()"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="'Pagina’s van eigen website ter voorbeeld (bijvoorbeeld voor opmaak/lay-out). Eventueel pagina’s van concurrentie'"
                        ></textarea>

                <div class="alert alert-danger" v-if="errors.example_pages">
                    <ul class="mb-0">
                        <li v-for="error in errors.example_pages" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3">
                <label for="extra_info" class="form-label" id="extra_infoLabel">Extra informatie</label>
                <textarea id="extra_info" class="form-control"
                        aria-labelledby="extra_infoLabel" v-model="extraInfo"
                        :class="{'validation-error': this.validation.errorFields.includes('extraInfo')}"
                        @change="validateForm()"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="'Hoeveel tijd je ongeveer voor de tekst rekent. Achtergrondinformatie die nog niet genoemd is. Aantal woorden bij benadering'"
                        ></textarea>

                <div class="alert alert-danger" v-if="errors.extra_info">
                    <ul class="mb-0">
                        <li v-for="error in errors.extra_info" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="row" v-if="textsId == null">
                <div class="mb-3 col-4">
                    <label for="priority" id="priorityLabel" class="form-label">Prioriteit*</label>
                        <multiselect
                        v-model="priority"
                        :options="['Hoog', 'Normaal']"
                        :searchable="true"
                        id="priority"
                        :class="{'validation-error': this.validation.errorFields.includes('priority')}"
                        @change="validateForm()"
                        ></multiselect>

                    <div class="alert alert-danger" v-if="errors.priority">
                        <ul class="mb-0">
                            <li v-for="error in errors.priority" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
                <div class="mb-3 col-4">
                    <label for="text_writer_id" id="text_writer_idLabel" class="form-label">Tekstschrijver</label>
                    <multiselect
                        v-model="textWriterId"
                        :options="users"
                        :searchable="true"
                        id="text_writer_id"
                        :class="{'validation-error': this.validation.errorFields.includes('textWriterId')}"
                        @change="validateForm()"
                    ></multiselect>

                    <div class="alert alert-danger" v-if="errors.text_writer_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.text_writer_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
                <div class="mb-3 col-4">
                    <label for="status_id" id="status_idLabel" class="form-label">Status*</label>
                    <multiselect
                        v-model="statusId"
                        :options="textstatus"
                        :searchable="true"
                        id="status_id"
                        :class="{'validation-error': this.validation.errorFields.includes('statusId')}"
                        @change="validateForm()"
                    ></multiselect>

                    <div class="alert alert-danger" v-if="errors.status_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.status_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-4" v-if="textsId == null">
                    <label class="form-label">Opgestuurd</label>
                    <div class="btn-group d-block" role="group" aria-label="Opgestuurd">
                        <button type="button" class="btn btn-outline-primary"
                                :class="{'active': wasPosted == 'Verstuurd'}"
                                @click="wasPosted = 'Verstuurd'">Ja
                        </button>
                        <button type="button" class="btn btn-outline-primary"
                                :class="{'active': wasPosted == 'Niet verstuurd'}"
                                @click="wasPosted = 'Niet verstuurd'">Nee
                        </button>
                        <button type="button" class="btn btn-outline-primary"
                                :class="{'active': wasPosted == 'N.V.T.'}"
                                @click="wasPosted = 'N.V.T.'">N.V.T.
                        </button>
                    </div>
                </div>

                <div class="mb-3 col-4">
                    <label class="form-label">Soort tekst</label>
                    <div class="btn-group d-block" role="group" aria-label="Soort tekst">
                        <button type="button" class="btn btn-outline-primary"
                                :class="{'active': typeOfText == 'SEO'}"
                                @click="typeOfText = 'SEO'">SEO
                        </button>
                        <button type="button" class="btn btn-outline-primary"
                                :class="{'active': typeOfText == 'Linkbuilding'}"
                                @click="typeOfText = 'Linkbuilding'">Linkbuilding
                        </button>
                    </div>
                </div>
            </div>

        <div class="row">
            <div class="mb-3 col-4">
                <label for="type_id" id="type_idLabel" class="form-label">Type*</label>
                    <multiselect
                    v-model="typeId"
                    :options="types"
                    :searchable="true"
                    id="type_id"
                    :class="{'validation-error': this.validation.errorFields.includes('typeId')}"
                    @change="validateForm()"
                ></multiselect>

                <div class="alert alert-danger" v-if="errors.type_id">
                    <ul class="mb-0">
                        <li v-for="error in errors.type_id" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3 col-4">
                <label for="active_date" class="form-label" id="active_dateLabel">Startmaand*</label>
                    <input type="month" name="active_date" id="active_date" class="form-control"
                    aria-labelledby="active_dateLabel" v-model="activeDate" :min="minDate"
                    :class="{'validation-error': this.validation.errorFields.includes('activeDate')}"
                    @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.active_date">
                        <ul class="mb-0">
                            <li v-for="error in errors.active_date" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>
        </div>

            <div class="mb-3">
                <label for="url" class="form-label" id="urlLabel">URL</label>
                    <input type="text" name="url" id="url" class="form-control"
                    aria-labelledby="urlLabel" v-model="url"
                    :class="{'validation-error': this.validation.errorFields.includes('url')}"
                    @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.url">
                        <ul class="mb-0">
                            <li v-for="error in errors.url" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>

            <div class="mb-3">
                <label for="cms" id="cmsLabel" class="form-label">CMS*</label>
                    <multiselect
                    v-model="cms"
                    :options="['Magento 1', 'Magento 2', 'ModX', 'Wordpress', 'WooCommerce', 'Elementor', 'Overige']"
                    :searchable="true"
                    id="cms"
                    :class="{'validation-error': this.validation.errorFields.includes('cms')}"
                    @change="validateForm()"
                ></multiselect>

                <div class="alert alert-danger" v-if="errors.cms">
                    <ul class="mb-0">
                        <li v-for="error in errors.cms" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="mb-3" v-if="textsId == null">
                <label for="drive_url" class="form-label" id="drive_urlLabel">Drive URL</label>
                    <input type="text" name="drive_url" id="drive_url" class="form-control"
                    aria-labelledby="drive_urlLabel" v-model="driveUrl"
                    :class="{'validation-error': this.validation.errorFields.includes('driveUrl')}"
                    @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.drive_url">
                        <ul class="mb-0">
                            <li v-for="error in errors.drive_url" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="submit" class="btn btn-primary" @click="submitForm()">
                <i class="las la-save"></i> Opslaan
            </button>

            <div class="alert alert-danger mt-3 mb-0" v-if="hasErrors">
                Er zijn fouten opgetreden bij het opslaan van het formulier.
            </div>
        </div>
    </div>

</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import axios from 'axios'
    import Tooltip from 'bootstrap/js/src/tooltip'
    import jQuery from 'jquery';


    export default {
        components: {
            Tooltip,
            Multiselect
        },
        data() {
            return {
                'textsId': null,
                'typeOfText': 'SEO',
                'activeDate': null,
                'projects': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'projectId': null,
                'title': null,
                'description': null,
                'targetGroup': null,
                'toneOfVoice': null,
                'primarySearchWord': null,
                'secondarySearchWord': null,
                'internalLinks': null,
                'examplePages': null,
                'extraInfo': null,
                'priority': 'Normaal',
                'users': [{
                    value: '',
                    label: 'Wordt geladen...',
                    role: ''
                }],
                'textWriterId': null,
                'textstatus': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'statusId': 1,
                'wasPosted': 'Niet verstuurd',
                'types': [{
                    value: '',
                    label: 'Wordt geladen...'
                }],
                'typeId': null,
                'url': null,
                'cms': null,
                'driveUrl': null,
                'text_creator_id': null,
                'validation': {
                    'errors': [],
                    'errorFields': []
                },
                'errors': {},
                'hasErrors': false,

            }
        },
        computed: {
            minDate() {
                const today = new Date();
                const year = today.getFullYear();
                let month = today.getMonth() + 1;

                if (month > 12) {
                    month = 1;
                    year += 1;
                }

                const formattedMonth = month.toString().padStart(2, '0');

                return `${year}-${formattedMonth}`;
            }
        },
        methods: {
        submitForm() {
            this.validateForm()

            if (this.validation.errors.length == 0) {
                let data = {
                    'texts_id': this.textsId,
                    'type_of_text': this.typeOfText,
                    'project_id': this.projectId,
                    'title': this.title,
                    'description': this.description,
                    'target_group': this.targetGroup,
                    'tone_of_voice': this.toneOfVoice,
                    'primary_search_word': this.primarySearchWord,
                    'secondary_search_word': this.secondarySearchWord,
                    'internal_links': this.internalLinks,
                    'example_pages': this.examplePages,
                    'extra_info': this.extraInfo,
                    'priority': this.priority,
                    'text_writer_id': this.textWriterId,
                    'status_id': this.statusId,
                    'was_posted': this.wasPosted,
                    'type_id': this.typeId,
                    'active_date': this.activeDate + '-01',
                    'url': this.url,
                    'cms': this.cms,
                    'drive_url': this.driveUrl,
                    'text_creator_id': this.text_creator_id
                }

                this.errors = {}
                this.hasErrors = false
                if (this.textsId != null) {
                    axios.patch('/api/seotexts/' + this.textsId, data).then(() => {
                        window.location = '/texts/overview';

                        this.$notify({
                            id: 'text-form-sent',
                            title: 'Text opgeslagen',
                            text: 'De tekst is succesvol opgeslagen',
                            type: 'success',
                            duration: 3000
                        })

                        jQuery('html,body').animate({scrollTop: 0});

                    }).catch(error => {
                        this.errors = error.response.data.errors
                        this.hasErrors = true
                    })
                } else {
                    axios.post('/api/seotexts', data).then(() => {
                        //window.location = '/texts/overview';

                        this.$notify({
                            id: 'text-form-sent',
                            title: 'Text opgeslagen',
                            text: 'De tekst is succesvol opgeslagen',
                            type: 'success',
                            duration: 3000
                        })

                        this.title = '';
                        jQuery('html,body').animate({scrollTop: 0});

                    }).catch(error => {
                        this.errors = error.response.data.errors
                        this.hasErrors = true
                    })
                }
            }
        },

        validateForm() {
            this.validation.errors = [];
            this.validation.errorFields = []
            if (this.projectId != null && this.projectId == '') {
                this.validation.errors.push('Geef een project op voor de tekst')
                this.validation.errorFields.push('projectId')
            }

        }
    },

    mounted() {

        if (this.$route.params.id) {

        this.emitter.emit('set-title', 'SEO tekst bewerken')

        this.textsId = this.$route.params.id
        fetch('/api/seotexts/' + this.textsId)
        .then(resp => resp.json())
            .then((json) => {
                this.projectId = json.project_id
                this.title = json.title
                this.description = json.description
                this.targetGroup = json.target_group
                this.toneOfVoice = json.tone_of_voice
                this.primarySearchWord = json.primary_search_word
                this.secondarySearchWord = json.secondary_search_word
                this.internalLinks = json.internal_links
                this.examplePages = json.example_pages
                this.extraInfo = json.extra_info
                this.priority = json.priority
                this.textWriterId = json.text_writer_id
                this.statusId = json.status_id
                this.wasPosted = json.was_posted
                this.typeId = json.type_id
                const activeDateParts = json.active_date.split('-');
            	this.activeDate = `${activeDateParts[0]}-${activeDateParts[1]}`;
                this.url = json.url
                this.cms = json.cms
                this.driveUrl = json.drive_url
            })

            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Overzicht',
                    url: '/texts/overview/'
                }
            ])

            this.editMode = true
        } else {

            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Overzicht',
                    url: '/texts/overview/'
                }
            ])
            this.emitter.emit('set-title', 'SEO tekst aanmaken')
        }

        fetch('/api/togglprojects')
            .then(resp => resp.json())
            .then(json => {
                this.projects = []
                for (let p in json) {
                    let project = json[p]
                    this.projects.push({
                        'label': project.project_name,
                        'value': project.toggl_project_id
                    })

                }
            })

        fetch('/api/textsstatus')
            .then(resp => resp.json())
            .then((json) => {
                this.textstatus = [];
                for (let s in json) {
                    let status = json[s];
                    this.textstatus.push({
                        'label': status.status_name,
                        'value': status.id
                    })
                }
            })

        fetch('/api/texttypes')
            .then(resp => resp.json())
            .then((json) => {
                this.types = [];
                for (let t in json) {
                    let type = json[t];
                    this.types.push({
                        'label': type.type_name,
                        'value': type.id
                    })
                }
            })

        fetch('/api/users')
            .then(resp => resp.json())
            .then((json) => {
                this.users = [];
                for (let u in json) {
                    let user = json[u];
                    this.users.push({
                        'label': user.name,
                        'value': user.id,
                        'role': user.role
                    });
                }

                this.users.sort((a, b) => {
                    if (a.role === 'Tekstschrijver' && b.role !== 'Tekstschrijver') {
                        return -1;
                    } else if (a.role !== 'Tekstschrijver' && b.role === 'Tekstschrijver') {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            });

    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7) url(../../../img/loading.gif) no-repeat center;
        background-size: 200px;
        z-index: 9999;
    }
</style>
