<template>
    <div class="row mb-3">
        <div class="col-6">
        <div class="card h-100">
            <div class="card-body align-content-center d-flex">
            <div class="d-flex justify-content-between mb-2">
                <div class="d-flex align-items-center gap-3">
                <div class="avatar">
                    <span class="avatar-initial bg-label-info rounded-circle">
                    <i class="las la-newspaper fs-4"></i>
                    </span>
                </div>
                <div class="card-info">
                    <h5 class="card-title mb-0 m3-2">{{ totalThisMonth }}</h5>
                    <small class="text-muted">Totaal aantal artikelen deze maand</small>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        <div class="col-6">
        <div class="card h-100">
            <div class="card-body align-content-center d-flex">
            <div class="d-flex justify-content-between mb-2">
                <div class="d-flex align-items-center gap-3">
                <div class="avatar">
                    <span class="avatar-initial bg-label-info rounded-circle">
                    <i class="las la-pen fs-4"></i>
                    </span>
                </div>
                <div class="card-info">
                    <h5 class="card-title mb-0 m3-2">{{ writtenThisMonth }}</h5>
                    <small class="text-muted">Aantal geschreven artikelen deze maand</small>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body table-responsive">

            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link"
                    :class="{ active: activeTab === 'seotexttable' }"
                    @click="activeTab = 'seotexttable'"
                    href="#">SEO teksten</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"
                    :class="{ active: activeTab === 'linkbuildingtexttable' }"
                    @click="activeTab = 'linkbuildingtexttable'"
                    href="#">Linkbuilding teksten</a>
                </li>
            </ul>

            <div class="btn-group d-block mt-3">
                <button v-for="(month, index) in months"
                        :key="index"
                        @click="filterByMonth(month)"
                        :class="{
                            'btn btn-primary': selectedMonth === month.month && selectedYear === month.year,
                            'btn btn-outline-primary': selectedMonth !== month.month || selectedYear !== month.year
                        }">
                    {{ getMonthName(month.month) }} {{ month.year }}
                </button>
            </div>

            <div class="tab-content">
                <div v-if="activeTab === 'seotexttable'">
                    <TextsTable :url="routes.seo" :columns="columns.seo"
                        v-if="routes.seo != null"
                                          :tableId="'seotexttable'"
                                          :activeTab="activeTab"></TextsTable>
                </div>
                <div v-if="activeTab === 'linkbuildingtexttable'">
                    <TextsTable :url="routes.linkbuilding" :columns="columns.linkbuilding"
                        v-if="routes.linkbuilding != null"
                                          :tableId="'linkbuildingtexttable'"
                                          :activeTab="activeTab"></TextsTable>
                </div>
            </div>
        </div>
    </div>

    <div v-if="showSeoModal" class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content" v-if="activeTab == 'seotexttable'">
                <div class="modal-header">
                    <h5 class="modal-title">{{ seoModalData.title }}</h5>
                    <button type="button" class="btn-close" @click="closeSeoModal"></button>
                </div>
                <div class="modal-body">
                    <p><strong>Omschrijving:</strong> {{ seoModalData.description }}</p>
                    <p><strong>Doelgroep:</strong> {{ seoModalData.target_group }}</p>
                    <p><strong>Tone of Voice:</strong> {{ seoModalData.tone_of_voice }}</p>
                    <p><strong>Primair zoekwoord:</strong> {{ seoModalData.primary_search_word }}</p>
                    <p><strong>Secundair(e) zoekwoord(en):</strong> {{ seoModalData.secondary_search_word }}</p>
                    <p><strong>Interne links:</strong> {{ seoModalData.internal_links }}</p>
                    <p><strong>Voorbeeldpagina's:</strong> {{ seoModalData.example_pages }}</p>
                    <p><strong>Extra informatie:</strong> {{ seoModalData.extra_info }}</p>
                    <p><strong>Project:</strong> {{ seoModalData.project?.project_name }}</p>
                    <p><strong>Type:</strong> {{ seoModalData.type?.type_name }}</p>
                    <p><strong>URL:</strong> {{ seoModalData.url }}</p>
                    <p><strong>CMS:</strong> {{ seoModalData.cms }}</p>
                    <p><strong>Drive URL:</strong>{{ seoModalData.drive_url }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" @click="editSeoText">SEO tekst bewerken</button>
                    <button type="button" class="btn btn-secondary" @click="closeSeoModal">Sluiten</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="showLinkbuildingModal" class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content" v-if="activeTab == 'linkbuildingtexttable'">
                <div class="modal-header">
                    <h5 class="modal-title">{{ linkbuildingModalData.title }}</h5>
                    <button type="button" class="btn-close" @click="closeLinkbuildingModal"></button>
                </div>
                <div class="modal-body">
                    <p><strong>Project:</strong> {{ linkbuildingModalData.project?.project_name }}</p>
                    <p><strong>Ankertekst:</strong> {{ linkbuildingModalData.anchor_texts[0] }}</p>
                    <p><strong>Anker URL:</strong> {{ linkbuildingModalData.anchor_urls[0] }}</p>
                    <p><strong>Ankertekst 2:</strong> {{ linkbuildingModalData.anchor_texts[1] }}</p>
                    <p><strong>Anker URL 2:</strong> {{ linkbuildingModalData.anchor_urls[1] }}</p>
                    <p><strong>Linkbuilding Website:</strong> {{ linkbuildingModalData.linkbuilding_websites }}</p>
                    <p><strong>Artikel URL:</strong> {{ linkbuildingModalData.article_url }}</p>
                    <p><strong>Opmerkingen:</strong> {{ linkbuildingModalData.comments }}</p>
                    <p><strong>Drive URL:</strong> {{ linkbuildingModalData.drive_url }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" @click="editLinkbuildingText">Linkbuilding tekst bewerken</button>
                    <button type="button" class="btn btn-secondary" @click="closeLinkbuildingModal">Sluiten</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import axios from 'axios'
import Tooltip from 'bootstrap/js/src/tooltip'
import 'datatables.net'
import TextsTable from './Table.vue'
import jQuery from "jquery";

export default {

  components: {
    Tooltip,
    Multiselect,
    TextsTable
  },
  data() {
    const currentDate = new Date();
    return {
        activeTab: 'seotexttable',
        currentUserRole: null,
        seotexts: [],
        linkbuildingtexts: [],
        showSeoModal: false,
        seoModalData: {},
        showLinkbuildingModal: false,
        linkbuildingModalData: {},
        months: [],
        selectedMonth: currentDate.getMonth() + 1,
        selectedYear: currentDate.getFullYear(),
        textstatus: [{
            value: '',
            label: 'Wordt geladen...'
        }],
        linkbuildingtextstatus: [{
            value: '',
            label: 'Wordt geladen...'
        }],
        texttypes: [{
            value: '',
            label: 'Wordt geladen...'
        }],
        users: [{
            value: '',
            label: 'Wordt geladen...',
            role: ''
        }],
        totalThisMonth: '...',
        writtenThisMonth: '...',
        startTimer: '00:00:00',
        routes: {
            seo: null,
            linkbuilding: null
        },
        columns: {
                seo: [
                    {data: 'timer', orderable: false, title: 'start timer:'},
                    {data: 'id', orderable: true, title: 'Titel'},
                    //{data: 'title', orderable: true, title: 'Titel', type: 'string', searchable: true},
                    {data: 'priority', orderable: true, title: 'Prioriteit', type: 'string'},
                    {data: 'text_writer', orderable: true, title: 'Schrijver', type: 'string', searchable: true, filterDropdown: true},
                    {data: 'status', orderable: true, title: 'Status', type: 'string', filterDropdown: true},
                    {data: 'was_posted', orderable: true, title: 'Opgestuurd', type: 'string', filterDropdown: true},
                    //{data: 'website', orderable: false, title: 'Klant', filterInput: true},
                    //{data: 'type', orderable: true, title: 'Type', filterDropdown: true},
                    //{data: 'url', orderable: false, title: 'URL', type: 'html'},
                    //{data: 'cms', orderable: true, title: 'CMS'},
                    {data: 'drive_url', orderable: false, title: 'Drive URL', type: 'html'},
                    {data: 'text_creator', orderable: false, title: 'Aangemaakt door', filterDropdown: true},
                    {data: 'actions', orderable: false, title: 'acties'}
                ],
                linkbuilding: [
                    {data: 'timer', orderable: false, title: 'start timer:'},
                    {data: 'id', orderable: false, title: 'Tekst'},
                    {data: 'priority', orderable: true, title: 'Prioriteit', type: 'string', filterDropdown: true},
                    // {data: 'website', orderable: true, title: 'Klant', type: 'string', filterInput: true},
                    {data: 'status', orderable: true, title: 'Status', type: 'string', searchable: true, filterDropdown: true},
                    {data: 'payed', orderable: true, title: 'Betaald', filterDropdown: true},
                    {data: 'writer', orderable: true, title: 'Schrijver', type: 'string', filterDropdown: true},
                    // {data: 'anchor_text_0', orderable: false, title: 'Ankertekst 1', filterInput: true},
                    // {data: 'anchor_url_0', orderable: false, title: 'Anker URL 1', type: 'html'},
                    // {data: 'anchor_text_1', orderable: false, title: 'Ankertekst 2', filterInput: true},
                    // {data: 'anchor_url_1', orderable: false, title: 'Anker URL 2', type: 'html'},
                    // {data: 'linkbuilding_websites', orderable: false, type: 'html', title: 'Linkbuilding website', filterInput: true},
                    // {data: 'article_url', orderable: true, type: 'html', title: 'Artikel URL'},
                    // {data: 'comments', orderable: true, title: 'Opmerkingen'},
                    {data: 'drive_url', orderable: true, type: 'html', title: 'Drive URL'},
                    {data: 'creator', orderable: true, title: 'Aangemaakt door', filterDropdown: true},
                    {data: 'actions', orderable: false, title: 'acties'}
                ],
        }
    }
  },

    watch: {
        activeTab(newTab) {
            jQuery('#seotexttable_export,#linkbuildingtexttable_export').addClass('d-none')
            jQuery('#' + newTab + '_export').removeClass('d-none')
            if (newTab === 'seotexttable') {
            this.fetchSeoTexts(this.selectedMonth, this.selectedYear);
            } else if (newTab === 'linkbuildingtexttable') {
            this.fetchLinkbuildingTexts(this.selectedMonth, this.selectedYear);
            }
            this.fetchMonths();
            this.fetchStatistics(this.selectedMonth, this.selectedYear);
        }
    },

  methods: {

    openSeoModal(id) {
        axios.get('/api/seotexts/' + id)
      .then(response => {
        this.seoModalData = response.data;
        this.showSeoModal = true;
      })
      .catch(error => {
        console.error('Er is een fout opgetreden bij het ophalen van de SEO-teksten:', error);
      });
    },
    closeSeoModal() {
        this.showSeoModal = false;
    },

    editSeoText() {
        window.location.href = `/texts/seotexts/${this.seoModalData.id}/edit`;
    },

    editLinkbuildingText() {
        window.location.href = `/texts/linkbuildingtexts/${this.linkbuildingModalData.id}/edit`;
    },

    openLinkbuildingModal(id) {
        axios.get('/api/linkbuildingtexts/' + id)
         .then(response => {
            this.linkbuildingModalData = response.data;

            if (this.linkbuildingModalData.anchor_text && this.linkbuildingModalData.anchor_url) {
                this.linkbuildingModalData.anchor_texts = this.linkbuildingModalData.anchor_text.split('¤ ');
                this.linkbuildingModalData.anchor_urls = this.linkbuildingModalData.anchor_url.split('¤ ');
            }

            this.showLinkbuildingModal = true;
         })
         .catch(error => {
            console.error('Er is een fout opgetreden bij het ophalen van de Linkbuilding teksten:', error);
         });
    },

    closeLinkbuildingModal() {
        this.showLinkbuildingModal = false;
    },

    makeRunningTimeEntry(id) {
        axios.patch(`/api/texts/timer/${id}`, {
            type: this.activeTab
        })
        console.log('data voor tijdregistratie: id:', id)
    },

    updateSeoDriveUrl(seotext) {
        this.updateSeoText(seotext, 'drive_url', seotext.drive_url);
    },

    updateLinkbuildingDriveUrl(linkbuildingtext) {
        this.updateLinkbuildingText(linkbuildingtext, 'drive_url', linkbuildingtext.drive_url);
    },

    updateSeoText(seotext, field, value) {

        const updatedData = {
            [field]: value
        };

        if (field === 'status_id' && value === '9') {
            const currentDateTime = new Date().toISOString();
            updatedData.finished_at = currentDateTime;
        } else {
            updatedData.finished_at = '';
        }

      axios.patch(`/api/seotexts/${seotext.id}`, updatedData)
        .then(() => {
        //console.log(updatedData)
            //console.log(`Field ${field} successfully updated for SEO text ID ${seotext.id}`);

            this.emitter.emit('datatable-redraw')
        })
        .catch(error => {
            console.error(`Error updating ${field} for SEO text ID ${seotext.id}:`, error);
        });
    },

    updateLinkbuildingText(linkbuildingtext, field, value) {

      const updatedData = {
            [field]: value
      };

      if (field === 'status_id' && value === '9') {
            const currentDateTime = new Date().toISOString();
            updatedData.finished_at = currentDateTime;
        } else {
            updatedData.finished_at = '';
        }

      axios.patch(`/api/linkbuildingtexts/${linkbuildingtext.id}`, updatedData)
        .then(() => {
           // console.log(`Field ${field} successfully updated for Linkbuilding text ID ${linkbuildingtext.id}`);
        })
        .catch(error => {
            console.error(`Error updating ${field} for Linkbuilding text ID ${linkbuildingtext.id}:`, error);
        });
    },

    fetchStatistics(month = null, year = null) {
        const apiUrl =
        this.activeTab === 'seotexttable'
            ? '/api/seotexts/statistics'
            : '/api/linkbuildingtexts/statistics';

        let urlWithParams = apiUrl;
        if (month && year) {
        urlWithParams += `?month=${month}&year=${year}`;
        }

        axios
        .get(urlWithParams)
        .then((response) => {
            this.totalThisMonth = response.data.totalThisMonth;
            this.writtenThisMonth = response.data.writtenThisMonth;
        })
        .catch((error) => {
            console.error('Error fetching statistics:', error);
        });
    },

    applyMonthFilterByRole() {
        if (this.currentUserRole === 'Tekstschrijver') {
            const currentMonth = new Date().getMonth() + 1;
            const currentYear = new Date().getFullYear();
            const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
            const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;

            this.months = this.months.filter(month => {
                return  (month.month === currentMonth && month.year === currentYear) ||
                        (month.month === nextMonth && month.year === nextYear);
            });
        }
    },

    fetchMonths() {
    let table = this.activeTab === 'seotexttable'
        ? 'seotexttable'
        : 'linkbuildingtexttable';

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;

    const predefinedMonths = [
        { month: currentMonth, year: currentYear },
        { month: nextMonth, year: nextYear }
    ];

    fetch(`/api/texts/months?table=${table}`)
        .then(response => response.json())
        .then(data => {

        const combinedMonths = [...predefinedMonths, ...data].filter((value, index, self) =>
            index === self.findIndex((t) => t.month === value.month && t.year === value.year)
        );

        combinedMonths.sort((a, b) => {
            if (a.year === b.year) {
            return b.month - a.month;
            }
            return b.year - a.year;
        });

        this.months = combinedMonths;
        this.applyMonthFilterByRole();
        })
        .catch(error => {
        console.error('Fout bij het ophalen van maanden:', error);
        });
    },

    filterByMonth(month) {

        this.selectedMonth = month.month;
        this.selectedYear = month.year;

        if (this.activeTab === 'seotexttable') {
            this.fetchSeoTexts(this.selectedMonth, this.selectedYear);
        } else {
            this.fetchLinkbuildingTexts(this.selectedMonth, this.selectedYear);
        }

        this.fetchStatistics(this.selectedMonth, this.selectedYear)
    },

    getMonthName(monthNumber) {
      const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
                          "Juli", "Augustus", "September", "Oktober", "November", "December"];
      return monthNames[monthNumber - 1];
    },

    fetchSeoTexts(month = null, year = null) {
        let apiUrl = '/texts/json/seo';

        if (month && year) {
            apiUrl += `?month=${month}&year=${year}`;
        }

        // this.seotexts = [];

         this.routes.seo = apiUrl;
        // if (this.seotexts == []) {
        //     axios.get(apiUrl)
        //     .then(response => {
        //     this.seotexts = response.data;
        //     })
        //     .catch(error => {
        //     console.error('Fout bij het ophalen van de SEO-teksten:', error);
        //     });
        // }

        // this.seotexts = [];
    },

    fetchLinkbuildingTexts(month = null, year = null) {
    let apiUrl = '/texts/json/linkbuilding';

    if (month && year) {
        apiUrl += `?month=${month}&year=${year}`;
    }

     this.routes.linkbuilding = apiUrl;

    // this.linkbuildingtexts = [];

    // axios.get(apiUrl)
    //     .then(response => {
    //     this.linkbuildingtexts = response.data;
    //     })
    //     .catch(error => {
    //     console.error('Fout bij het ophalen van de Linkbuilding-teksten:', error);
    //     });
     },
  },

  mounted() {
    this.emitter.emit('set-title', 'Tekstoverzicht')

    this.emitter.on('display-seo-modal', (id) => {
        this.openSeoModal(id)
    });

    this.emitter.on('display-linkbuilding-modal', (id) => {
        this.openLinkbuildingModal(id)
    });

    this.emitter.on('update-seo-text', ({ id, field, value }) => {
        this.updateSeoText({ id }, field, value);
    });

    this.emitter.on('update-linkbuilding-text', ({ id, field, value }) => {
        this.updateLinkbuildingText({ id }, field, value);
    });

    this.emitter.on('update-seo-driveurl', ({ id, value }) => {
        this.updateSeoDriveUrl({ id, drive_url: value });
    });

    this.emitter.on('update-linkbuilding-driveurl', ({ id, value }) => {
        this.updateLinkbuildingDriveUrl({ id, drive_url: value });
    });

    this.emitter.on('start-timer', (id, title, website) => {
         this.makeRunningTimeEntry(id, title, website)
    })

    fetch('/api/texts/routes')
            .then(resp => resp.json())
            .then(json => this.routes = json)

    fetch('/api/textsstatus')
        .then(resp => resp.json())
        .then((json) => {
            this.textstatus = [];
            for (let s in json) {
                let status = json[s];
                this.textstatus.push({
                    'label': status.status_name,
                    'value': status.id
                })
            }
        })

    fetch('/api/linkbuildingtextsstatus')
        .then(resp => resp.json())
        .then((json) => {
            this.linkbuildingtextstatus = [];
            for (let s in json) {
                let status = json[s];
                this.linkbuildingtextstatus.push({
                    'label': status.status_name,
                    'value': status.id
                })
            }
        })

    fetch('/api/texttypes')
        .then(resp =>resp.json())
        .then((json) => {
            this.texttypes = [];
            for (let t in json) {
                let type = json[t];
                this.texttypes.push({
                    'label': type.type_name,
                    'value': type.id
                })
            }
        })

    fetch('/api/users')
        .then(resp => resp.json())
        .then((json) => {
            this.users = [];
            for (let u in json) {
                let user = json[u];
                this.users.push({
                    'label': user.name,
                    'value': user.id,
                    'role': user.role
                });
            }
        })

        fetch('/api/current-user-role')
            .then(resp => resp.json())
            .then(json => {
                this.currentUserRole = json.current_user_role;
                this.applyMonthFilterByRole();
            });


    this.fetchStatistics();
    this.fetchSeoTexts();
    this.fetchLinkbuildingTexts();
    this.fetchMonths();
  }
}
</script>

<style>

.large-multiselect-writer {
    min-width: 210px;
    font-size: 1rem;
}

.large-textarea {
    min-width: 175px;
    font-size: 1rem;
}

.large-multiselect-posted {
    min-width: 128px;
    font-size: 1rem;
}

.large-multiselect-priority {
    min-width: 90px;
    font-size: 1rem;
}

.large-multiselect-status {
    min-width: 185px;
    font-size: 1rem;
}
</style>
